import React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { FullScreenLayout } from '@components/layouts';
import { BreadcrumbContainer } from '@components/breadcrumb-container/breadcrumb-container';
import { Section } from '@components/section/section';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen';
import { Container, Typography } from '@material-ui/core';
import { Alert } from '@components/alert/alert';
import { useSetHeaderContext } from '../hooks/useSetHeaderContext';

interface ContactQuery {
  imageSharp: {
    id: string;
    gatsbyImageData: IGatsbyImageData;
  };
}

const Contact: React.FC<PageProps> = () => {
  const data = useStaticQuery<ContactQuery>(graphql`
    query ContactImageQuery {
      imageSharp(resize: { originalName: { regex: "/^contact-backdrop/" } }) {
        id
        gatsbyImageData
      }
    }
  `);

  useSetHeaderContext({
    image: data.imageSharp.gatsbyImageData,
    title: 'Kontakt',
    subtitle: 'daj się poznać',
    height: 300,
  });

  return (
    <FullScreenLayout>
      <Container>
        <BreadcrumbContainer
          links={[{ link: '/contact', text: 'Kontakt', icon: faEnvelopeOpen }]}
        />
        <Section>
          <Alert>
            <Typography variant="body2">Strona w budowie</Typography>
          </Alert>
        </Section>
      </Container>
    </FullScreenLayout>
  );
};

export default Contact;
